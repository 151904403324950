import { AfterViewInit, Component, ElementRef, Injector, ViewChild, signal } from '@angular/core';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { TableModule } from '@src/app/core/shared/components/table/table.module';
import { BRAND_BASE_URL, BRAND_STATUS_UPDATE, BRANDS_TABS } from '../../core';

import { ApiEndPointConfiguration } from '@src/app/app-types';
import { BaseComponent } from '@src/app/core/base';
import { TableComponent } from '@src/app/core/shared/components/table/table.component';
import {
  AllowedTableActions,
  TableActions,
} from '@src/app/core/interfaces/table-actions.interface';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModule } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.module';
import {
  AllowedTableActionsData,
  ROW_NOT_CLICKABLE,
} from '@src/app/core/constants/table-actions.constant';
import { AppStatus } from '@src/app/core/shared/components/app-status/app-status.component';
import FileSaver from 'file-saver';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';

import { ApiResponseInterface } from '@src/app/core/interfaces';
import { BASE_MATERIAL, FINISH } from '@src/app/core/constants/routes.constant';
import { ITEM_STATUS, DEFAULT_DEBOUNCE_TIME } from '@src/app/core/constants';
import { BrandsRepository } from '@src/app/features/brands/core/repositories/brands.repository';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'brand-listing',
  standalone: true,
  templateUrl: './brands-listing.component.html',
  styleUrls: ['./brands-listing.component.scss'],
  imports: [
    ...COMMON_MODULES,
    TableModule,
    NgbNavModule,
    NgbDropdownModule,
    ConfirmDialogModule,
    AppStatus,
  ],
})
export class BrandListingComponent extends BaseComponent implements AfterViewInit {
  public BRANDS_TABS = BRANDS_TABS;
  public active: number = 1;
  formData: any = {
    id: '###',
    name: '',
    isActivated: true,
  };
  // +(this.route.params.get('id') || 1);
  @ViewChild(TableComponent, { static: false }) tableCompRef!: TableComponent;
  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;
  public dropdownData: any = [
    {
      name: 'CONSTANTS.ADMIN_USER',
      link: 'add',
      param: { type: 'admin_user' },
    },
    {
      name: 'CONSTANTS.APP_USER',
      link: 'add',
      param: { type: 'app_user' },
    },
  ];
  protected formChange = false; // flage to detect changes in forms of child component
  protected setFormChange = (value: boolean) => {
    this.formChange = value;
  };

  @ViewChild('fileInput') fileInput!: ElementRef;
  public isEditPermission = this.sharedDataService.checkPermission(
    [this.permissionsList.UPDATE_ROLES],
    undefined,
  );
  private selectedRows = signal([]);
  public selectedFile: any[] = [];
  public status = ITEM_STATUS;

  public confirmEventAction: 'active' | 'delete' | null = null;

  public endPointConfiguration: ApiEndPointConfiguration = {
    method: 'GET',
    url: BRAND_BASE_URL,
    // body: {
    //   'filter.roles.code': '$in:commercial_sales_agent,inspector,sales_agent,driver,attendant',
    // },
  };

  public allowedTableActions: AllowedTableActions = AllowedTableActionsData;
  public displayedColumnsViewArray: any[] = [];

  /**
   * Constructor for the AppUserListingComponent.
   *
   * @param {StoreManagementService} storeManagementService - Service for managing user data
   * @param {Injector} injector - Angular's injector service
   * @param {PageSettingsService} pageSettingsService - Service for managing page settings
   */
  constructor(
    private injector: Injector, // Angular's injector service
    private pageSettingsService: PageSettingsService, // Service for managing page settings
    private brandRepository: BrandsRepository, // Service for managing page settings
  ) {
    // Calling the parent constructor (BaseComponent) and passing the injector
    super(injector);
    this.route.data.subscribe((data: any) => {
      // Access query parameters here
      if (data?.type === BASE_MATERIAL) {
        this.active = 2;
      } else if (data?.type === FINISH) {
        this.active = 3;
      } else {
        this.active = 1;
      }
    });
    // Initializing allowed table actions
    this.allowedTableActions.pending = true; // Setting 'pending' action as allowed
    this.allowedTableActions.draft = false; // Setting 'draft' action as not allowed

    // Creating new page settings for the user management page
    const newSettings = {
      link1: {
        title: 'BREADCRUMBS.PRODUCTS_MANAGEMENT.TITLE', // Title for the page
        name: 'BREADCRUMBS.BRANDS.TITLE', // Name for the breadcrumb
        link: this.routeList.BRANDS, // Link for the breadcrumb
      },
    };

    // Using the page settings service to update the page settings
    this.pageSettingsService.updatePageSettings(newSettings);
  }

  /**
   * Initializes the displayed columns view array after the view has been initialized.
   * Uses setTimeout to delay execution and avoid ExpressionChangedAfterItHasBeenCheckedError.
   *
   * @return {void}
   */
  ngAfterViewInit(): void {
    // Using setTimeout to delay execution and avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      // Array of objects defining the displayed columns
      this.displayedColumnsViewArray = [
        {
          key: 'id',
          outputKey: 'id',
          editable: false,
          value: 'COMPONENTS.BRANDS.BRAND_ID', // Localization key for column header
          type: 'text', // Type of column
          sortable: true, // Whether column is sortable
          className: 'w-15', // CSS class for column
        },
        {
          key: 'name',
          outputKey: 'name',
          value: 'COMPONENTS.BRANDS.BRAND_NAME',
          type: 'text',
          sortable: true,
          editable: true,
          className: ' text-truncate',
        },
        {
          key: 'status',
          value: 'COMPONENTS.BRANDS.STATUS',
          headingClass: 'ps-4',
          type: 'status',
          sortable: false,
          editable: true,
          disabled: true,
          defaultTitle: true,
          options: [
            {
              label: 'GENERAL.BUTTONS.ACTIVATE',
              value: 'active',
              icon: 'tick-circle',
            },
            {
              label: 'GENERAL.BUTTONS.DEACTIVATE',
              value: 'inactive',
              icon: 'close-circle',
            },
          ],
          endpoint: BRAND_STATUS_UPDATE,
          success: this.translate.instant('MSGS.CATEGORY.UPDATE_CATEGORY_STATUS'),
          successSecond: this.translate.instant('MSGS.CATEGORY.UPDATE_CATEGORY_STATUS_DEACTIVATED'),
          alert: this.translate.instant('MSGS.CATEGORY.ARE_YOU_SURE_ACTIVATE'),
          alertSecond: this.translate.instant('MSGS.CATEGORY.ARE_YOU_SURE_DEACTIVATE'),
          error: this.translate.instant('MSGS.USERS.UNABLE_TO_UPDATE_CATEGORY_STATUS'),
        },
        ...this.sharedDataService.checkPermission([this.permissionsList.UPDATE_USERS], {
          key: 'delete',
          value: '',
          type: 'button',
          class: ' btn-icon-table ms-auto d-block',
          iconClass: 'icon icon-trash-filled middle',
          iconSubmitClass: 'icon icon-tick-circle middle',
          callback: this.havePermissionToDelete,
        }),
        ...this.sharedDataService.checkPermission([this.permissionsList.UPDATE_USERS], {
          key: 'delete',
          value: '',
          type: 'button',
          actionKey: 'edit',
          class: ' btn-icon-table d-block',
          iconClass: 'icon icon-edit-pencil middle',
          iconCancelClass: 'icon icon-cross middle',
          callback: this.havePermissionToDelete,
        }),
      ];
    }, 500); // Delay of 500 milliseconds

    // Second setTimeout to execute another action after the first one
    setTimeout(() => {
      // Remove 'readonly' attribute from elements with specified IDs
      document.getElementById('email')?.removeAttribute('readonly');
      document.getElementById('password')?.removeAttribute('readonly');
    }, 1000); // Delay of 1000 milliseconds
  }

  public havePermissionToDelete = (permission: any) => {
    // Check if the permission object is an array and if it contains a role with code 'super_admin'
    return (
      Array.isArray(permission?.roles) && // Check if permission.roles is an array
      permission?.roles.some((role: any) => role?.code === 'super_admin') // Check if any role has code 'super_admin'
    );
  };

  /**
   * Checks if the given data contains an item with a role code of 'super_admin'.
   *
   * @param {any} data - The data to be checked.
   * @return {boolean} Returns true if 'super_admin' role is found, false otherwise.
   */
  private hasSuperAdminRole(data: any) {
    let hasSuperAdmin = false;
    // Loop through the data array and check if any item has a role with code 'super_admin'
    data?.forEach((item: any) => {
      if (item?.roles?.some((role: any) => role?.code === 'super_admin')) {
        hasSuperAdmin = true; // Set to true if 'super_admin' role is found
      }
    });
    return hasSuperAdmin; // Return true if 'super_admin' role is found, false otherwise
  }

  protected onSave(formData: any): void {
    // let { valid, value } = this.formGroup;
    // if(!this.image?.file){
    //   this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.INVALID_IMAGE'), 'error');
    //   return;
    // }
    // value.file = this.image?.file;

    // let formData = new FormData();
    // formData.append('name', value.name);
    // formData.append('description', value.description);
    // formData.append('file', this.image.file);
    // formData.append('order', value.order);

    // if (valid) {
    // Determine API endpoint and HTTP method based on whether it's an update or creation
    let apiContainer, msg;
    if (formData?.id) {
      const id = this.helperService.clone(formData.id);
      delete formData.id;
      apiContainer = this.brandRepository.update(id, formData);
      msg = this.translate.instant('COMPONENTS.BRANDS.UPDATE_SUCCESS');
    } else {
      // formData['type'] = this.key;
      apiContainer = this.brandRepository.create(formData);
      msg = this.translate.instant('COMPONENTS.BRANDS.SUCCESS_MESSAGE');
    }

    apiContainer
      .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe({
        /**
         * Executes the next callback function asynchronously. If the response status is SUCCESS_STATUS or SUCCESS_STATUS_CODE,
         * it updates the calendar and shows error messages if any. Otherwise, it shows success message.
         *
         * @param {any} res - The response object.
         * @return {Promise<void>} A promise that resolves when the function completes.
         */
        next: async (res: any) => {
          this.updateTable(msg);
        },
      });
    // }
  }

  /**
   * Handles different actions based on the event object.
   *
   * @param {any} e - The event object.
   * @return {void}
   */
  public onElementClick(e: any) {
    if (!e.id && e.value === 'editable' && e.element !== 'fromEditableView' && e.editFormData) {
      delete e.editFormData.id;
      this.onSave(e.editFormData);
      return;
    } else if (
      !e.id &&
      e.value === 'editable' &&
      e.element === 'fromEditableView' &&
      e.editFormData
    ) {
      console.log('e.editFormData');
      this.onSave(e.editFormData);
      return;
    } else if (!e.id && e.value === 'cancel_editable' && e.editFormData) {
      console.log('e.editFormData');
      return;
    } else if (!e.id && e.value === 'editView') {
      console.log('e.editFormData');
      return;
    }
    // Handle different actions based on the event object

    // If 'element' property is an array with length greater than 0, set selectedRows
    if (e.element.length > 0) {
      this.selectedRows.set(e.element);
    }
    // If 'element' property is not clickable and has an ID 'from_td', navigate to user listing route
    else if (!ROW_NOT_CLICKABLE.includes(e.action) && e.id && e.id === 'from_td') {
      // this.helperService.goToRoute(this.routeList.CATEGORIES_NEW + `/${e.element.id}`);
      return;
    }
    // If 'element' property has an ID but no action, return
    else if (e.id) {
      return;
    }

    // Switch statement to handle different actions
    switch (e.action) {
      case 'delete':
        // If confirmDialCompRef exists, open delete dialog
        if (this.confirmDialCompRef) {
          this.confirmEventAction = 'delete';
          this.confirmDialCompRef.openDialog(e.element);
        }
        break;
      case 'email':
        // If action is 'email', navigate to user listing route
        this.helperService.goToRoute(this.routeList.CATEGORIES_NEW + `/${e.element.id}`);
        break;
    }
  }

  /**
   * Processes the modal close event and performs actions based on the event type.
   *
   * @param {any} event - The event object containing information about the modal close event.
   * @return {void}
   */
  public processModalClose(event: {
    data: any;
    eventType: string | null;
    confirmed: boolean;
    action: 'confirm' | 'dismiss';
  }) {
    // Switch case based on event type
    if (event?.eventType === 'delete') {
      // If action is confirm
      if (event.action === 'confirm') {
        // Check permission to delete
        if (!this.havePermissionToDelete(event.data)) {
          // Get current user
          let user = this.helperService.getCurrentUser();
          // If the user is trying to delete their own profile
          if (event?.data?.id == user?.id) {
            // Show error message
            this.httpService.showMessage(
              this.translate.instant('MSGS.USERS.OWN_PROFILE_DELETION'),
              'error',
            );
            return;
          }
          // Delete user profile
          this.brandRepository
            .delete(event.data?.id)
            .pipe(this.destroy$())
            .subscribe({
              /**
               * Handles the response from the delete operation.
               *
               * @param {any} res - The response from the delete operation.
               * @return {void}
               */
              next: (response: unknown) => {
                const res = response as ApiResponseInterface<any>;

                // If delete operation is successful
                if (
                  res.status === this.constantList.SUCCESS_STATUS ||
                  res.status === this.constantList.SUCCESS_STATUS_CODE
                ) {
                  // Update table
                  this.updateTable(this.translate.instant('MSGS.GENERAL.DELETE_BRAND_SUCCESS'));
                } else {
                  // Show error message
                  this.httpService.showMessage(
                    this.translate.instant('MSGS.USERS.UNABLE_TO_DELETE'),
                    'error',
                  );
                }
              },
            });
        }
      }
    }
  }

  /**
   * Following method will work against particullar action performed
   *
   * @param e Action emitted by filter action in table
   */
  public tableAction({ key, params }: TableActions) {
    // Check if action is not related to downloading or importing
    if (key !== 'download_template' && key !== 'download_selected_rows' && key !== 'import') {
      // If rows are selected
      if (this.selectedRows().length > 0) {
        // Prepare ids to send along with API request
        const ids = this.selectedRows().map((item: any) => {
          return item.id;
        });
        // If action is related to user status
        if (key === 'active' || key === 'inactive' || key === 'pending') {
          let rows = this.selectedRows();
          let user: any = this.helperService.getCurrentUser();

          // If selected rows contain super admin, prevent status change
          if (!this.hasSuperAdminRole([user])) {
            let super_Admin = false;

            rows.forEach((element: any) => {
              if (element?.roles.length > 0) {
                element.roles.forEach((role: any) => {
                  if (role?.code?.includes('super_admin')) {
                    super_Admin = true;
                  }
                });
              }
            });
            if (super_Admin) {
              // Show error message
              this.httpService.showMessage(
                this.translate.instant('MSGS.USERS.SUPER_ADMIN_STATUS_CHANGE'),
                'error',
              );
              return;
            }
          }

          let status = key === 'pending' ? 'draft' : key;
          // Update user status
          // this.categoryRepository
          //   .updateDetailByPayload({
          //     userIds: ids,
          //     status: status,
          //   })
          //   .pipe(this.destroy$())
          //   .subscribe({
          //     /**
          //      * Handles the response from the update operation.
          //      *
          //      * @param {any} res - The response from the update operation.
          //      * @return {void}
          //      */
          //     next: (response: unknown) => {
          //       const res = response as ApiResponseInterface<any>;

          //       // If update operation is successful
          //       if (res.status === this.constantList.SUCCESS_STATUS) {
          //         // Update table
          //         this.updateTable(this.translate.instant('MSGS.USERS.UPDATE_USER_STATUS'));
          //       }
          //     },
          //   });
        }
        // For other actions like delete
        else if (key === 'delete') {
          if (!this.hasSuperAdminRole(this.selectedRows())) {
            // Delete selected rows
            this.brandRepository
              .delete(ids[0])
              .pipe(this.destroy$())
              .subscribe({
                /**
                 * Handles the response from the delete operation.
                 *
                 * @param {any} res - The response from the delete operation.
                 * @return {void}
                 */
                next: (response: unknown) => {
                  const res = response as ApiResponseInterface<any>;

                  // If delete operation is successful
                  if (res.status === this.constantList.SUCCESS_STATUS) {
                    // Update table
                    this.updateTable(this.translate.instant('MSGS.USERS.DELETE_BRAND_SUCCESS'));
                  }
                },
              });
          } else {
            // Show error message
            this.httpService.showMessage(
              this.translate.instant('MSGS.USERS.UNABLE_TO_DELETE_SUPER_ADMIN'),
              'error',
            );
          }
        }
      } else {
        // Show error message if no row is selected
        this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.SELECT_ROW'), 'error');
      }
    } else {
      // For download and import actions
      switch (key) {
        case 'download_template':
          // this.downloadTemplate();
          break;
        case 'import':
          this.import();
          break;
      }
    }
  }
  /**
   * Downloads a template file using a GET request.
   *
   * @private
   * @return {void}
   */
  // private downloadTemplate() {
  //   // Make a GET request to download a template file
  //   this.userRepository
  //     .downloadGet()
  //     .pipe(this.destroy$())
  //     .subscribe({
  //       /**
  //        * Handles the response from the server after downloading a template file.
  //        *
  //        * @param {any} res - The response object from the server.
  //        * @return {void} This function does not return anything.
  //        */
  //       next: (response: unknown) => {
  //         const res = response as ApiResponseInterface<any>;

  //         // Check if response body exists
  //         if (res?.body) {
  //           // Set the file name for download
  //           const fileName = this.translate.instant('COMPONENTS.USERS.IMPORT_FILE_NAME');
  //           // Download the file
  //           this.downloadFile(res?.body, fileName + '.csv', 'text/csv');
  //         } else {
  //           // Show error message if unable to download template
  //           this.httpService.showMessage(
  //             this.translate.instant('MSGS.USERS.UNABLE_TO_DOWNLOAD_TEMPLATE'),
  //             'error',
  //           );
  //         }
  //       },
  //     });
  // }

  // Download a file
  private downloadFile(data: any, fileName: string, contentType: string) {
    const blob = new Blob([data], { type: contentType });
    // Use FileSaver library to save the file
    FileSaver.saveAs(blob, fileName);
  }

  /**
   * Triggers the click event of the file input element to open the file selection dialog.
   *
   * @return {void} This function does not return anything.
   */
  private import() {
    // Trigger file input click event
    this.fileInput.nativeElement.click();
  }

  // File selection event on data uploader
  onFileChange(event: any) {
    this.selectedFile = [];
    for (const file of event.target.files) {
      if (this.isValidFile(file)) {
        // Add selected file to the array and proceed with upload
        this.selectedFile.push({ file, fileName: file.name });
        // this.onDataUpload();
      } else {
        // Show error message if file format is not valid
        this.httpService.showMessage(
          this.translate.instant('MSGS.USERS.ONLY_CSV_FILE_ERROR'),
          'error',
          false,
        );
        return;
      }
    }
    // Reset file input value after file selection
    this.fileInput.nativeElement.value = '';
  }
  // Check if the file is valid
  private isValidFile(file: File): boolean {
    // Define an array of valid file types
    const validFileTypes = [
      // 'xls',
      // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      // 'application/vnd.ms-excel',
      // 'xlsx',
      'csv',
      'text/csv',
    ];
    // Check if the file type matches any of the valid file types
    return validFileTypes.some(type => file.type.includes(type));
  }

  // Function to handle data upload
  // onDataUpload() {
  //   // Check if no file is selected
  //   if (this.selectedFile.length < 1) {
  //     this.httpService.showMessage(this.translate.instant('MSGS.USERS.SELECT_FILE'), 'warning');
  //     return;
  //   }

  //   // If only one file is selected
  //   if (this.selectedFile.length === 1) {
  //     // Create a FormData object and append the selected file
  //     const formData = new FormData();
  //     formData.append('excelFile', this.selectedFile[0].file);

  //     // Make a POST request to upload the file
  //     this.userRepository
  //       .importData(formData)
  //       // Subscribe to the response
  //       .pipe(this.destroy$())
  //       .subscribe({
  //         // Handle the response
  //         next: (response: unknown) => {
  //           const res = response as ApiResponseInterface<any>;

  //           // If the status is between 200 and 210 (success)
  //           if (res?.status >= 200 && res?.status <= 210) {
  //             // Show success message
  //             this.httpService.showMessage(
  //               this.translate.instant('MSGS.USERS.IMPORT_USERS'),
  //               'success',
  //             );
  //             // Reload the table data
  //             this.tableCompRef.loadResourcesPage();
  //           } else {
  //             // Show error message
  //             this.httpService.showMessage(
  //               this.translate.instant('MSGS.USERS.UNABLE_TO_IMPORT'),
  //               'error',
  //             );
  //           }
  //         },
  //       });
  //   }
  // }

  // Function to update the table
  private updateTable(msg: string) {
    // Show success message
    this.httpService.showMessage(msg, 'success');
    // Reload the table data
    this.tableCompRef.loadResourcesPage();
    // Clear the bulk select list
    this.tableCompRef.bulkSelectList = [];
    // Clear the selected rows
    this.selectedRows.set([]);
  }

  /////////////////////////////////

  public tableData: any[] = [
    { id: 1, name: 'John Doe', age: 25 },
    { id: 2, name: 'Jane Smith', age: 30 },
    { id: 3, name: 'Michael Johnson', age: 35 },
  ];

  public currentRow: any = null;
  public isEditMode2: boolean = false;

  addRow() {
    const newRow = {
      id: this.tableData.length + 1,
      name: `New User ${this.tableData.length + 1}`,
      age: Math.floor(Math.random() * 50) + 20,
    };
    this.tableData.push(newRow);
  }

  editRow(row: any) {
    this.currentRow = { ...row }; // Create a copy of the row for editing
    this.isEditMode2 = true;
  }

  saveRow() {
    if (this.currentRow) {
      const index = this.tableData.findIndex(row => row.id === this.currentRow.id);
      this.tableData[index] = this.currentRow; // Update the row
      this.resetForm();
    }
  }

  resetForm() {
    this.currentRow = null;
    this.isEditMode2 = false;
  }
}
