@if (element && !element?.editableView) {
  @if (!canUpdateStatus || column?.disabled) {
    <button
      [disabled]="element.disable"
      [class]="
        'btn-dropdown btn-180 btn-outline btn-outline-primary ms-2 mb-0' +
        ' ' +
        (column?.defaultTitle
          ? element?.isActivated
            ? 'active'
            : 'inactive'
          : returnValue(element)?.value)
      "
      id="dropdownStatus">
      <i [class]="'icon middle me-1 icon-' + returnValue(element)?.icon"></i>
      {{
        column?.defaultTitle
          ? element?.isActivated
            ? ('COMMON.TEXT.ACTIVE' | translate)
            : ('COMMON.TEXT.INACTIVE' | translate)
          : ('COMMON.TEXT.' +
              status[column.keyValue ? element[column.keyValue] : element?.status]
                ?.replace('-', '')
                ?.replaceAll(' ', '')
                ?.toUpperCase() | translate)
      }}
    </button>
  } @else {
    <div class="d-inline-block" ngbDropdown>
      <button
        [disabled]="element.disable"
        [class]="
          'dropdown-toggle btn-dropdown btn-180 btn-outline btn-outline-primary ms-2 mb-0' +
          ' ' +
          (column?.defaultTitle
            ? element?.isActivated
              ? 'active'
              : 'inactive'
            : returnValue(element)?.value)
        "
        id="dropdownStatus"
        ngbDropdownToggle>
        <i [class]="'icon middle me-1 icon-' + returnValue(element)?.icon"></i>
        {{
          column?.defaultTitle
            ? element?.isActivated
              ? ('COMMON.TEXT.ACTIVE' | translate)
              : ('COMMON.TEXT.INACTIVE' | translate)
            : ('COMMON.TEXT.' +
                status[column.keyValue ? element[column.keyValue] : element?.status]
                  ?.replace('-', '')
                  ?.replaceAll(' ', '')
                  ?.toUpperCase() | translate)
        }}
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownStatus">
        @for (item of options; track item) {
          <button ngbDropdownItem (click)="openConfirmationModal(item)">
            <i [class]="'icon middle me-1 icon-' + item?.icon"></i>
            {{ item?.label | translate }}
          </button>
        }
      </div>
    </div>
  }
} @else {
  <div class="d-inline-block" ngbDropdown>
    <button
      [class]="
        'dropdown-toggle btn-dropdown btn-180 btn-outline btn-outline-primary mb-0 ' +
        ((!isChangeOnLocal && element?.isActivated) ||
        ((!element || isChangeOnLocal) && options[currentState]?.icon === 'tick-circle')
          ? 'active'
          : 'inactive')
      "
      id="dropdownStatus"
      ngbDropdownToggle>
      <i
        [class]="
          'icon middle me-1 icon-' +
          (!isChangeOnLocal && element ? returnValue(element)?.icon : options[currentState]?.icon)
        "></i>
      <span style="text-transform: capitalize">
        {{
          !isChangeOnLocal && element
            ? element?.isActivated
              ? ('COMMON.TEXT.ACTIVE' | translate)
              : ('COMMON.TEXT.INACTIVE' | translate)
            : (options[currentState]?.value | translate)
        }}
      </span>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownStatus">
      @for (item of options; track item; let i = $index) {
        <button ngbDropdownItem (click)="onChangeStatus(i)">
          <i [class]="'icon middle me-1 icon-' + item?.icon"></i>
          {{ item?.label | translate }}
        </button>
      }
    </div>
  </div>
}

<app-confirm-dialog
  [closeText]="'GENERAL.BUTTONS.CLOSE' | translate"
  [confirmText]="'GENERAL.BUTTONS.CONFIRM' | translate"
  [message]="
    column?.defaultTitle
      ? element?.isActivated
        ? column.alert
        : column.alertSecond
      : ('MSGS.GENERAL.ARE_YOU_SURE' | translate)
  "
  (closeDialog)="processModalClose($event)"></app-confirm-dialog>
