<!-- <app-table
  [canUpdateStatus]="isEditPermission"
  [endPointConfiguration]="endPointConfiguration"
  [displayedColumnsViewArray]="displayedColumnsViewArray"
  [enableTableAction]="false"
  [canUpdateStatus]="true"
  [allowedTableActions]="allowedTableActions"
  notFoundMessage="MSGS.USERS.NO_RECORDS_AVAILABLE_TO_DISPLAY"
  (tableAction)="tableAction($event)"
  (elementClick)="onElementClick($event)"></app-table>

<app-confirm-dialog
  [confirmText]="'GENERAL.BUTTONS.DELETE' | translate"
  [closeText]="'GENERAL.BUTTONS.CLOSE' | translate"
  message="{{ 'MSGS.USERS.DELETE_USER' | translate }}"
  extraIcon="icon-trash-filled"
  [eventType]="confirmEventAction"
  (closeDialog)="processModalClose($event)"></app-confirm-dialog>

<input
  #fileInput
  type="file"
  style="display: none"
  (change)="onFileChange($event)"
  accept=".csv, text/csv" /> -->

<div class="user-profile">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>{{ 'COMPONENTS.TAGS.STYLE_TAGS' | translate }}</a>
      <ng-template ngbNavContent>
        <tag-table title="Style" [key]="TAGS_TABS.style"></tag-table>
      </ng-template>
    </li>
    <!-- [disabled]="pageMode === 'add'" -->
    <li [ngbNavItem]="2">
      <a ngbNavLink>{{ 'COMPONENTS.TAGS.BASE_MATERIAL_TAGS' | translate }}</a>
      <ng-template ngbNavContent>
        <tag-table title="Base Material" [key]="TAGS_TABS.base_material"></tag-table>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>{{ 'COMPONENTS.TAGS.FINISH_TAGS' | translate }}</a>
      <ng-template ngbNavContent>
        <tag-table title="Finish" [key]="TAGS_TABS.finish"></tag-table>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="py-4 px-0"></div>
</div>
