<app-table
  *ngIf="requestBody['filter.user.id']"
  [linkText]="'GENERAL.BUTTONS.NEW' | translate"
  linkIcon="plus-square"
  [showCreateButton]="false"
  [searchable]="false"
  [requestBody]="requestBody"
  notFoundMessage="MSGS.USERS.USER_HAS_NO_ACTIVITIES"
  [paramFilters]="paramFilters"
  [endPointConfiguration]="endPointConfiguration"
  [displayedColumnsViewArray]="displayedColumnsViewArray"
  (tableAction)="tableAction($event)"
  (elementClick)="onElementClick($event)"></app-table>
