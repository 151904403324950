<app-table
  [linkText]="'GENERAL.BUTTONS.NEW' | translate"
  linkIcon="plus-square"
  [link]="isEditPermission ? routeList.ADD_PATH : ''"
  [queryParams]="{ type: 'admin_user' }"
  [paramFilters]="paramFilters"
  [endPointConfiguration]="endPointConfiguration"
  [displayedColumnsViewArray]="displayedColumnsViewArray"
  [enableTableAction]="false"
  notFoundMessage="MSGS.SUB_CATEGORIES.NO_SUB_CATEGORY_AVAILABLE_TO_DISPLAY"
  (tableAction)="tableAction($event)"
  (elementClick)="onElementClick($event)"></app-table>

<app-confirm-dialog
  [confirmText]="'GENERAL.BUTTONS.DELETE' | translate"
  [closeText]="'GENERAL.BUTTONS.CLOSE' | translate"
  message="{{ 'MSGS.SUB_CATEGORIES.DELETE_SUB_CATEGORY' | translate }}"
  extraIcon="icon-trash-filled"
  [eventType]="confirmEventAction"
  (closeDialog)="processModalClose($event)"></app-confirm-dialog>