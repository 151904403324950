import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiResponseInterface } from '@src/app/core/interfaces/api-response.interface';

import { HttpService } from '@src/app/core/services/http.service';
import {
  USER_BY_ID_BASE_URL,
  SYSTEM_ROLES_BASE_URL,
} from '@src/app/features/user-management/core/constants/apis_list';
import {
  USER_MANAGEMENT_BULK_DELETE,
  USER_MANAGEMENT_IMPORT_EXCEL,
  USER_MANAGEMENT_DOWNLOAD_TEMPLATE,
  USER_MANAGEMENT_BULK_STATUS,
  USER_MANAGEMENT_STATUS_UPDATE,
  UPDATE_USER_PICTURE_BASE_URL,
  USER_MANAGEMENT_BASE_URL,
  UPDATE_USER_PASSWORD_BASE_URL,
} from '@src/app/features/user-management/core';

@Injectable({
  providedIn: 'root',
})
export class SystemRepository {
  constructor(private httpService: HttpService) {}
  getAllRoles(query: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('GET', SYSTEM_ROLES_BASE_URL, {
      search: query,
      limit: 100,
    });
  }
}
