<form [formGroup]="formGroup" novalidate>
  <!-- <div *ngIf="pageMode !== 'add'" class="d-flex gap-3 align-items-center mb-4">
    <div class="wd-100">
      <img
        [src]="profileImage || 'assets/images/user-profile-placeholder.png'"
        class="rounded-circle w-100 user-img"
        alt="user" />
    </div>
    <input
      [attr.disabled]="pageMode === 'view' ? true : null"
      [class.disabled]="pageMode === 'view'"
      [viewPermission]="[this.permissionsList.UPDATE_USERS]"
      type="file"
      id="upload1"
      hidden
      (change)="onPictureUpload($event)"
      accept="image/png, image/jpg, image/jpeg" />

    <label
      role="button"
      for="upload1"
      [class.disabled]="pageMode === 'view'"
      [viewPermission]="[this.permissionsList.UPDATE_USERS]"
      class="btn btn-outline btn-outline-primary btn-140">
      {{ 'COMPONENTS.USERS.UPLOAD_PHOTO' | translate }}
    </label>
  </div> -->

  <div class="row">
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="firstName" class="required">
          {{ 'COMPONENTS.USERS.FIRST_NAME' | translate }}
        </label>
        <input
          [class.disabled]="pageMode === 'view'"
          formControlName="firstName"
          class="form-control"
          type="text"
          appNoConsecutiveSpaces
          id="firstName"
          name="firstName"
          placeholder="Charly" />
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{ control: formGroup.controls['firstName'] }"></ng-container>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="lastName" class="required">
          {{ 'COMPONENTS.USERS.LAST_NAME' | translate }}
        </label>
        <input
          [class.disabled]="pageMode === 'view'"
          formControlName="lastName"
          name="lastName"
          id="lastName"
          appNoConsecutiveSpaces
          class="form-control"
          type="text"
          placeholder="Robinson" />
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{ control: formGroup.controls['lastName'] }"></ng-container>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="email" [class.required]="hasSpecificRole === false">
          {{ 'COMPONENTS.USERS.EMAIL' | translate }}
        </label>
        <input
          id="email"
          appNoConsecutiveSpaces
          [class.disabled]="
            pageMode === 'view' || (pageMode === 'edit' && hasSpecificRole === false)
          "
          name="email"
          class="form-control"
          formControlName="email"
          type="email"
          readonly
          onfocus="this.removeAttribute('readonly');"
          placeholder="charly.robinson@domain.com" />
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{ control: formGroup.controls['email'] }"></ng-container>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="gender" class="required">
          {{ 'COMPONENTS.USERS.GENDER' | translate }}
        </label>
        <ng-select
          [clearSearchOnAdd]="true"
          [class.disabled]="pageMode === 'view'"
          [searchable]="false"
          [clearable]="false"
          formControlName="gender"
          name="gender"
          class="w-100 cus-select clear-disable"
          placeholder="{{ 'COMPONENTS.USERS.GENDER' | translate }}">
          <ng-option value="male">{{ 'COMPONENTS.USERS.MALE' | translate }}</ng-option>
          <ng-option value="female">
            {{ 'COMPONENTS.USERS.FEMALE' | translate }}
          </ng-option>
        </ng-select>
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{ control: formGroup.controls['gender'] }"></ng-container>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="role" class="required">
          {{ 'COMPONENTS.USERS.ROLE' | translate }}
        </label>
        <ng-select
          [clearSearchOnAdd]="true"
          [class.disabled]="disableRoles === true"
          class="bg-white w-100 cus-select clear-disable"
          [searchable]="true"
          [clearable]="false"
          formControlName="role"
          bindValue="id"
          [closeOnSelect]="true"
          (search)="onSearch($event)"
          [bindLabel]="getLanguageSpecificKey('name')"
          name="role"
          placeholder="{{ 'COMPONENTS.USERS.SELECT_ROLES' | translate }}">
          <ng-option *ngFor="let type of rolesList | keyvalue" [value]="type.value">
            {{ type.key }}
          </ng-option>
        </ng-select>
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{ control: formGroup.controls['role'] }"></ng-container>
      </div>
    </div>
    <div class="col-md-6"></div>
  </div>

  <div
    *ngIf="pageMode === 'edit'"
    class="d-flex justify-content-end gap-2"
    [viewPermission]="[this.permissionsList.UPDATE_USERS]">
    <button
      (click)="setProfile()"
      type="button"
      class="btn btn-outline btn-outline-primary btn-140">
      {{ 'GENERAL.BUTTONS.RESET' | translate }}
    </button>
    <button
      type="button"
      appDebounceClick
      (debounceClick)="submitProfile('active')"
      class="btn btn-primary btn-140">
      {{ 'GENERAL.BUTTONS.UPDATE' | translate }}
    </button>
  </div>

  <div
    *ngIf="pageMode === 'add'"
    class="d-flex justify-content-end gap-2"
    [viewPermission]="[this.permissionsList.UPDATE_USERS]">
    <button
      (click)="setProfile('cancel')"
      type="button"
      class="btn btn-outline btn-outline-primary btn-140">
      {{ 'GENERAL.BUTTONS.CANCEL' | translate }}
    </button>
    <button
      type="button"
      appDebounceClick
      (debounceClick)="submitProfile('active')"
      class="btn btn-primary btn-140">
      {{ 'GENERAL.BUTTONS.SAVE' | translate }}
    </button>
  </div>
</form>

<!-- Form Validation Handling Errors -->
<ng-template #reqErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
    @if (control?.hasError('required')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
    } @else if (control?.hasError('pattern')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_FORMAT' | translate }}</small>
    } @else if (
      name === 'employeeId' && (control?.hasError('minlength') || control?.hasError('maxlength'))
    ) {
      <small class="text-danger">
        {{ 'COMMON.VALIDATION.USER_ID_VALIDATION' | translate }}
      </small>
    }
  }
</ng-template>
<ng-template #tipContent>
  @if (this.userType === 'app_user') {
    <ul class="mb-0 ps-3">
      <li class="tx-12">{{ 'COMPONENTS.USERS.LENGTH_SIX_DIGITS' | translate }}</li>
    </ul>
  } @else {
    <ul class="mb-0 ps-3">
      <li class="tx-12">{{ 'COMPONENTS.USERS.LENGTH_EIGHT_SIXTEEN_CHARACTERS' | translate }}</li>
      <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_CAPITAL' | translate }}</li>
      <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_LOWER' | translate }}</li>
      <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_NUMBER' | translate }}</li>
    </ul>
  }
</ng-template>
