<div class="homeowner-detail">

    <div class="d-flex justify-content-end align-items-center mb-3">

        <!-- Profile Image -->
        <img src="{{baseModel.profileImageUrl}}" alt="" class="img-fluid rounded me-2"
            style="width: 50px; height: 50px; object-fit: cover;">

        <div ngbDropdown>
            <button class="dropdown-toggle btn-dropdown btn-200 btn-outline btn-outline-primary ms-2 mb-0"
                [ngClass]="baseModel.profileStatus.replace('-', '')?.replace('_', '')?.replaceAll(' ', '')?.toLowerCase()"
                id="dropdownStatus" ngbDropdownToggle>
                @switch (baseModel.profileStatus) {
                @case ('accepted') {
                <i class="icon middle me-2 icon-tick-circle"></i>
                }
                @case ('rejected') {
                <i class="icon middle me-2 icon-close-circle"></i>
                }
                @default {
                <i class="icon middle me-2 icon-minus-cirlce"></i>
                }
                }
                {{ 'COMMON.TEXT.' + baseModel.profileStatus.replace('-', '')?.replace('_', '')?.replaceAll(' ',
                '')?.toUpperCase() | translate }}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownStatus">
                @switch (baseModel.profileStatus) {
                @case ('incomplete') {
                <button ngbDropdownItem (click)="updateUserStatus('incomplete', baseModel.id)">
                    <i class="icon middle me-2 icon-minus-cirlce"></i>
                    {{ 'GENERAL.BUTTONS.INCOMPLETE' | translate }}
                </button>
                }
                @case ('pending_review') {
                <button ngbDropdownItem (click)="updateUserStatus('pending_review', baseModel.id)">
                    <i class="icon middle me-2 icon-minus-cirlce"></i>
                    {{ 'GENERAL.BUTTONS.PENDINGREVIEW' | translate }}
                </button>
                <button ngbDropdownItem (click)="updateUserStatus('accepted', baseModel.id)">
                    <i class="icon middle me-2 icon-tick-circle"></i>
                    {{ 'GENERAL.BUTTONS.ACCEPTED' | translate }}
                </button>
                <button ngbDropdownItem (click)="updateUserStatus('rejected', baseModel.id)">
                    <i class="icon middle me-2 icon-close-circle"></i>
                    {{ 'GENERAL.BUTTONS.REJECTED' | translate }}
                </button>
                }
                @default {
                <button ngbDropdownItem (click)="updateUserStatus('accepted', baseModel.id)">
                    <i class="icon middle me-2 icon-tick-circle"></i>
                    {{ 'GENERAL.BUTTONS.ACCEPTED' | translate }}
                </button>
                <button ngbDropdownItem (click)="updateUserStatus('rejected', baseModel.id)">
                    <i class="icon middle me-2 icon-close-circle"></i>
                    {{ 'GENERAL.BUTTONS.REJECTED' | translate }}
                </button>
                }
                }
            </div>
        </div>


    </div>

    <!-- Section 1: General Info -->
    <div class="col-lg-12">
        <div class="card mb-4">
            <div class="card-body">
                <h3 class="mb-3">{{ 'COMPONENTS.USERS.GENERAL_INFO' | translate }}</h3>

                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">First Name</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.firstName || 'NA' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="mb-0">Last Name</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.lastName || 'NA' }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Email Address</p>
                    </div>
                    <div class="col-sm-9">
                        <p class="text-muted mb-0">{{baseModel.email || 'NA' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Section 2: Shipping Address -->
    <div class="col-lg-12">
        <div class="card mb-4">
            <div class="card-body">
                <h3 class="mb-3">{{ 'COMPONENTS.USERS.SHIPPING_ADDRESS' | translate }}</h3>

                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">First Name</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.shippingFirstName || 'NA' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="mb-0">Last Name</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.shippingLastName || 'NA' }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Country</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.shippingCountry || 'NA' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="mb-0">City</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.shippingCity || 'NA' }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">State</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.shippingState || 'NA' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="mb-0">Zip Code</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.shippingZipCode || 'NA' }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Address</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.shippingAddress || 'NA' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Section 2: Billing Address -->
    <div class="col-lg-12">
        <div class="card mb-4">
            <div class="card-body">
                <h3 class="mb-3">{{ 'COMPONENTS.USERS.BILLING_ADDRESS' | translate }}</h3>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">First Name</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.billingFirstName || 'NA' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="mb-0">Last Name</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.billingLastName || 'NA' }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Country</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.billingCountry || 'NA' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="mb-0">City</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.billingCity || 'NA' }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">State</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.billingState || 'NA' }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="mb-0">Zip Code</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.billingZipCode || 'NA' }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Address</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="text-muted mb-0">{{baseModel.billingAddress || 'NA' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<app-confirm-dialog [confirmText]="dialogConfirmText" [closeText]="'GENERAL.BUTTONS.CLOSE' | translate"
    [message]="dialogMessage" extraIcon="icon-trash-filled" [eventType]="confirmEventAction"
    (closeDialog)="processModalClose($event)"></app-confirm-dialog>