import { AllowedTableActions } from '@src/app/core/interfaces/table-actions.interface';
export interface TableActions {
  actionName: string;
  icon?: string;
  key:
    | 'active'
    | 'inactive'
    | 'draft'
    | 'delete'
    | 'download_template'
    | 'download_selected_rows'
    | 'import';
}

export const AllowedTableActionsData: AllowedTableActions = {
  active: true,
  inactive: true,
  delete: true,
  draft: true,
  pending: false,
  download_selected_rows: true,
  download_template: true,
  import: true,
};

export const FILTER_BUTTONS = [
  {
    key: 'filter.startDate',
    type: 'current',
    selectedValue: '',
    resetFilters: ['filter.startAt', 'filter.isExpired'],
    extraFilter: { value: false, key: 'filter.isExpired' },
    operation: '$lte:',
  },
  {
    key: 'filter.startDate',
    type: 'upcoming',
    selectedValue: '',
    resetFilters: ['filter.startAt', 'filter.isExpired'],
    extraFilter: { value: false, key: 'filter.isExpired' },
    operation: '$gt:',
  },
  {
    key: 'filter.isExpired',
    type: 'expired',
    selectedValue: '',
    resetFilters: ['filter.isExpired'],
    extraFilter: { value: true, key: 'filter.isExpired' },
    operation: '$eq:',
  },
];
export const FILTER_BUTTONS_ROSTER = [
  {
    key: 'filter.fromDate',
    type: 'past',
    selectedValue: '',
    operation: '$lt:',
  },
  {
    key: 'filter.fromDate',
    type: 'current',
    selectedValue: '',
    operation: '$eq:',
  },
  {
    key: 'filter.fromDate',
    type: 'upcoming',
    selectedValue: '',
    operation: '$gt:',
  },
];

export const ROW_NOT_CLICKABLE = ['delete', 'status', '', 'selected Rows', 'system'];

export const FILTER_BUTTONS_TIMETABLE = [
  {
    key: 'filter.lineDate',
    type: 'current',
    selectedValue: '',
    operation: '$eq:',
  },
  {
    key: 'filter.lineDate',
    type: 'upcoming',
    selectedValue: '',
    operation: '$gt:',
  },
  {
    key: 'filter.lineDate',
    type: 'expired',
    selectedValue: '',
    operation: '$lt:',
  },
];

export const FILTER_BUTTONS_EMPLOYE_ROSTER = [
  {
    key: 'filter.fromDate',
    type: 'current',
    selectedValue: '',
    operation: '$eq:',
  },
  {
    key: 'filter.fromDate',
    type: 'upcoming',
    selectedValue: '',
    operation: '$gt:',
  },
  {
    key: 'filter.fromDate',
    type: 'expired',
    selectedValue: '',
    operation: '$lte:',
  },
];

export const FILTER_BUTTONS_TRANSACTION = [
  {
    key: 'filter.startAt',
    type: 'sevenDays',
    selectedValue: '',
    resetFilters: ['filter.startAt', 'filter.endAt'],
    operation: '$btw:',
  },
  {
    key: 'filter.startAt',
    type: 'yesterday',
    selectedValue: '',
    resetFilters: ['filter.startAt', 'filter.endAt'],
    operation: '$btw:',
  },
  {
    key: 'filter.startAt',
    type: 'today',
    selectedValue: '',
    resetFilters: ['filter.startAt', 'filter.endAt'],
    operation: '$btw:',
  },
];

export const FILTER_BUTTONS_HOLIDAYS = [
  {
    key: 'filter.endDate',
    type: 'expired',
    resetKey: 'filter.endDate',
    resetFilters: ['filter.startDate'],
    selectedValue: '',
    operation: '$lt:',
  },
];

export const CHOOSE_FILTER_IMAGE_URL = 'empty-reports.svg';
