<div class="user-profile">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="1">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-8">
            <a ngbNavLink>{{ 'COMPONENTS.USERS.INTERIOR_DESIGNER_PROFILE' | translate }}</a>
          </div>
          <div class="col-4 align-self-end">
            <div class="d-inline-block" ngbDropdown>
              <button
                class="dropdown-toggle btn-dropdown btn-200 btn-outline btn-outline-primary ms-2 mb-0"
                [ngClass]="baseModel.profileStatus.replace('-', '')?.replace('_', '')?.replaceAll('
  ', '')?.toLowerCase()"
                id="dropdownStatus"
                ngbDropdownToggle>
                @switch (baseModel.profileStatus) {
                  @case ('accepted') {
                    <i class="icon middle me-2 icon-tick-circle"></i>
                  }
                  @case ('rejected') {
                    <i class="icon middle me-2 icon-close-circle"></i>
                  }
                  @default {
                    <i class="icon middle me-2 icon-minus-cirlce"></i>
                  }
                }
                {{ 'COMMON.TEXT.' + baseModel.profileStatus.replace('-', '')?.replace('_', '')?.replaceAll('
  ', '')?.toUpperCase() | translate }}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownStatus">
                @switch (baseModel.profileStatus) {
                  @case ('incomplete') {
                    <button ngbDropdownItem (click)="updateUserStatus('incomplete', baseModel.id)">
                      <i class="icon middle me-2 icon-minus-cirlce"></i>
                      {{ 'GENERAL.BUTTONS.INCOMPLETE' | translate }}
                    </button>
                  }
                  @case ('pending_review') {
                      <button ngbDropdownItem (click)="updateUserStatus('pending_review', baseModel.id)">
                        <i class="icon middle me-2 icon-minus-cirlce"></i>
                        {{ 'GENERAL.BUTTONS.PENDINGREVIEW' | translate }}
                      </button>
                      <button ngbDropdownItem (click)="updateUserStatus('accepted', baseModel.id)">
                        <i class="icon middle me-2 icon-tick-circle"></i>
                        {{ 'GENERAL.BUTTONS.ACCEPTED' | translate }}
                      </button>
                      <button ngbDropdownItem (click)="updateUserStatus('rejected', baseModel.id)">
                        <i class="icon middle me-2 icon-close-circle"></i>
                        {{ 'GENERAL.BUTTONS.REJECTED' | translate }}
                      </button>
                  }
                  @default {
                    <button ngbDropdownItem (click)="updateUserStatus('accepted', baseModel.id)">
                      <i class="icon middle me-2 icon-tick-circle"></i>
                      {{ 'GENERAL.BUTTONS.ACCEPTED' | translate }}
                    </button>
                    <button ngbDropdownItem (click)="updateUserStatus('rejected', baseModel.id)">
                      <i class="icon middle me-2 icon-close-circle"></i>
                      {{ 'GENERAL.BUTTONS.REJECTED' | translate }}
                    </button>
                  }
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template ngbNavContent>
        <div class="col-lg-8">
          <div class="card mb-4">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">First Name</p>
                </div>
                <div class="col-sm-3">
                  <p class="text-muted mb-0">{{baseModel.firstName}}</p>
                </div>
                <div class="col-sm-3">
                  <p class="mb-0">Last Name</p>
                </div>
                <div class="col-sm-3">
                  <p class="text-muted mb-0">{{baseModel.lastName}}</p>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">Email</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{baseModel.email}}</p>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">Status</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{baseModel.status}}</p>
                </div>
              </div>
            </div>
          </div>
      </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="py-4 px-0"></div>
</div>


<app-confirm-dialog
  [confirmText]="dialogConfirmText"
  [closeText]="'GENERAL.BUTTONS.CLOSE' | translate"
  [message]="dialogMessage"
  extraIcon="icon-trash-filled"
  [eventType]="confirmEventAction"
  (closeDialog)="processModalClose($event)"></app-confirm-dialog>