import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '@src/app/core/base';
import { takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModule } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.module';
import { PROFILE_STATUS, PROFILE_STATUS_UPDATE } from '../../../core';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserRepository } from '../../../core/repositories/user.repository';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import { UserManagementService } from '@src/app/features/user-management/core';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
@Component({
  selector: 'app-home-owner-detail',
  templateUrl: './home-owner-detail.component.html',
  styleUrls: ['./home-owner-detail.component.scss'],
  preserveWhitespaces: true,
  standalone: true,
  imports: [
    ...COMMON_MODULES, 
    NgbNavModule,
    NgbNavModule,
    NgSelectModule,
    ConfirmDialogModule,
    NgbDropdownModule,
  ],
})
export class HomeOwnerDetailComponent extends BaseComponent implements OnInit {

  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;
  public dialogMessage: any = '';
  public dialogConfirmText: any = '';
  public confirmEventAction: string = '';
  public status = PROFILE_STATUS;
  // public active: number = 1;
  public user_Type: string = 'admin_user';

  /**
   * Component constructor
   * @param injector injects required services
   * @param pageSettingsService service for managing page settings in BaseComponent
   */
  constructor(
    injector: Injector,
    private userRepository: UserRepository,
    private userManagementService: UserManagementService,
    private pageSettingsService: PageSettingsService, // Service for managing page settings
  ) {
    super(injector);
    // Get the user from the resolver
    this.getUser();

    const newSettings = {
      link1: {
        title: 'BREADCRUMBS.USER_MANAGEMENT.TITLE', // Title for the page
        name: 'MENU.HOME_OWNER_USERS', // Name for the breadcrumb
        link: this.routeList.APP_HOME_OWNER, // Link for the breadcrumb
      },
      link2: {
        title: 'BREADCRUMBS.USER_MANAGEMENT.HOME_OWNER_DETAIL', // Title for the page
        name: 'MENU.HOME_OWNER_USERS', // Name for the breadcrumb
        link: null,
      },
    };

    this.pageSettingsService.updatePageSettings(newSettings);

  }

  /**
   * Initializes the component and performs any necessary setup operations.
   *
   * This function is called when the component is first created and is used to
   * retrieve any necessary data from the URL parameters, such as the email and
   * token, and perform any initial setup tasks.
   *
   * @return {void} This function does not return a value.
   */
  ngOnInit(): void {
    //Take params from Url ,check for email and token
  }

  /**
   * Subscribes to the data observable from the ActivatedRoute and
   * sets the baseModel property with the data from the observable.
   */
  getUser() {
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        if (data?.homeOwner) {
          /**
           * The user data from the ActivatedRoute data observable.
           */
          this.baseModel = data?.homeOwner;
        
        }
      });
    // this.activatedRoute.queryParams.subscribe((params: any) => {
    //   if (params['type']) {
    //     this.user_Type = params['type'];
    //   }
    // });
  }

  /**
   * Processes the modal close event
   *
   * @param event the event data containing the action and event type
   */
  public processModalClose(event: {
    data: any;
    eventType: string | null;
    confirmed: boolean;
    action: 'confirm' | 'dismiss';
  }) {
    if (event.action == 'confirm') {
      // If event type is delete
      if (event.eventType == 'delete') {
        this.userRepository
          // Call API to delete user
          .delete(String(event.data?.id))
          .pipe(this.destroy$())
          .subscribe({
            // Successful API response
            next: (response: unknown) => {
              const res = response as ApiResponseInterface<any>;

              if (res.status === this.constantList.SUCCESS_STATUS) {
                this.userManagementService.isDataModified = false;
                this.sharedDataService.dataModified.next({
                  component: 'user-about',
                  isModified: this.userManagementService.isDataModified,
                  data: {},
                });
                // Navigate to user listing page
                if (this.user_Type === 'admin_user') {
                  this.helperService.goToRoute(this.routeList.ADMIN_USER_LISTING);
                } else {
                  this.helperService.goToRoute(this.routeList.APP_USER_LISTING);
                }
                // Show success message
                this.httpService.showMessage(
                  this.translate.instant('MSGS.USERS.DELETE_USER_SUCCESS'),
                  'success',
                );
              } else {
                // Show error message
                this.httpService.showMessage(
                  this.translate.instant('MSGS.USERS.UNABLE_TO_DELETE'),
                  'error',
                );
              }
            },
          });
      } else {

      this.httpService
      .requestEntity('PATCH', PROFILE_STATUS_UPDATE.replace('{id}', event.data?.id), {
        profileStatus: event?.eventType,
      })
      .pipe(this.destroy$())
      .subscribe({
        /**
         * Executes the next callback function asynchronously. If the response status is equal to the SUCCESS_STATUS constant,
         * it shows a success message using the httpService.showMessage method and calls the loadResourcesPage method.
         * Otherwise, it shows an error message using the httpService.showMessage method.
         *
         * @param {any} res - The response object.
         * @return {void} This function does not return anything.
         */
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;
          
          if (res.status === this.constantList.SUCCESS_STATUS) {
            // Update the user status
            this.baseModel.profileStatus = event?.eventType;
            // Show success message
            this.httpService.showMessage(
              this.translate.instant('MSGS.GENERAL.STATUS_UPDATED_SUCCESS'),
              'success',
            );
          } else {
            // Show error message
            this.httpService.showMessage(
              this.translate.instant('MSGS.GENERAL.STATUS_UPDATED_SUCCESS'),
              'error',
            );
          }
        },
      });
      }
    }
  }

  /**
   * Update the user status
   *
   * @param profileStatus the new status of the user
   * @param id the user id
   */
    public updateUserStatus(profileStatus: string, id: string) {
      // Log the status and the base model
      // Check if the status is different from the current user's status
      if (profileStatus !== this.baseModel.profileStatus) {
        // If the confirm dialog component is defined, open the dialog
        if (this.confirmDialCompRef) {
          // Update the confirm event action
          this.confirmEventAction = profileStatus;
          // Set the dialog message
          this.dialogMessage = this.translate.instant('MSGS.GENERAL.ARE_YOU_SURE');
          // Set the dialog confirm button text
          this.dialogConfirmText = this.translate.instant('GENERAL.BUTTONS.CONFIRM');
          // Open the dialog
          this.confirmDialCompRef.openDialog(this.baseModel);
          
        }
      }
    }
}

