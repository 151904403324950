<app-table
  [linkText]="'GENERAL.BUTTONS.NEW' | translate"
  linkIcon="plus-square"
  [link]="isEditPermission ? routeList.ADD_PATH : ''"
  [queryParams]="{ type: 'admin_user' }"
  [paramFilters]="paramFilters"
  [endPointConfiguration]="endPointConfiguration"
  [displayedColumnsViewArray]="displayedColumnsViewArray"
  [enableTableAction]="false"
  (tableAction)="tableAction($event)"
  (elementClick)="onElementClick($event)"></app-table>
<!-- [canUpdateStatus]="false" -->

<app-confirm-dialog
  [confirmText]="'GENERAL.BUTTONS.DELETE' | translate"
  [closeText]="'GENERAL.BUTTONS.CLOSE' | translate"
  message="{{ 'MSGS.USERS.DELETE_USER' | translate }}"
  extraIcon="icon-trash-filled"
  [eventType]="confirmEventAction"
  (closeDialog)="processModalClose($event)"></app-confirm-dialog>

<input
  #fileInput
  type="file"
  style="display: none"
  (change)="onFileChange($event)"
  accept=".csv, text/csv" />
